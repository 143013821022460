<template>
    <div>
        <van-nav-bar
                placeholder
                fixed
                left-arrow
                :border='false'
                @click-left="$router.go(-1)">
            <template #left>
                <van-icon name="arrow-left" size="25" color="#0A1C33"/>
            </template>
        </van-nav-bar>
        <div class="div">
            <div class="login">
                <div class="login-active">
                    注册
                </div>
            </div>
            <div class="login-input">
                <input type="number" placeholder="请输入手机号" v-model="phone" class="name-input" maxlength="11">
            </div>
            <div class="login-input">
                <input type="text" placeholder="请输入验证码" v-model="code" class="name-input">
                <div class="login-code" :class="code_show?'code-active':''" @click="codeClick">
                    {{yzmText}}
                </div>
            </div>
            <div class="login-input">
                <input type="password" placeholder="请设置密码" v-model="password" class="name-input">
            </div>
            <div class="login-input">
                <input type="password" placeholder="请再次输入密码" v-model="password1" class="name-input">
            </div>
            <div class="login-input">
                <input type="text" placeholder="请输入邀请码" v-model="please" class="name-input">
            </div>

            <div class="please">已有账号？
                <span class="blue" @click="login">登录</span>
            </div>
            <div class="login-button button-active" v-if="display" @click="LoginClick">
                注册
            </div>
            <div class="login-button" v-else>
                注册
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoginRegistered',
        data() {
            return {
                phone: '',
                code: '',
                password: '',
                password1: '',
                please: '',
                display: false,
                code_show: false,
                yzmText: '获取验证码',
                time: 60,
            }
        },
        watch: {
            phone(e) {
                if (e.length == 11) {
                    if (this.time == 60) {
                        this.code_show = true
                    }
                } else {
                    this.code_show = false
                }
                if (e.length == 11 && this.code && this.password && this.password1) {
                    this.display = true;
                } else {
                    this.display = false;
                }
            },
            code(e) {
                if (e && this.phone.length == 11 && this.password && this.password1) {
                    this.display = true;
                } else {
                    this.display = false;
                }
            },
            password(e) {
                if (e && this.phone.length == 11 && this.code && this.password1) {
                    this.display = true;
                } else {
                    this.display = false;
                }
            },
            password1(e) {
                if (e && this.phone.length == 11 && this.code && this.password) {
                    this.display = true;
                } else {
                    this.display = false;
                }
            },
        },
        methods: {
            LoginClick() {
                this.$axios.post('/api/register', {
                    phone: this.phone,
                    code: this.code,
                    password: this.password,
                    password_confirm: this.password1,
                    invite_code: this.please,
                })
                .then(res=>{
                    if (res.data.code == 200) {
                        this.$toast.success('注册成功');
                        this.$router.replace({
                            path: '/login'
                        })
                    }
                })
                .catch(err=>{
                    this.$toast(err.response.data.message);
                })
            },
            codeClick() {
                if (!this.code_show) return
                  this.$ajax({
                    method: 'POST',
                    url: '/api/common/sms_send',
                    dataType:'json',
                    data: {'mobile':this.phone,'type':'register'}
                })
                    .then(res => {
                 
                let timer = setInterval(() => {
                    if (this.time == 0) {
                        clearInterval(timer);
                        this.yzmText = '获取验证码';
                        this.code_show = true;
                        this.time = 60
                    } else {
                        this.yzmText = this.time + '秒后再次获取';
                        this.code_show = false;
                        this.time--;
                    }
                }, 1000)
                    })
                    .catch(err => {
                        this.$toast('发送失败');
                    })
               
              
            },
            login() {
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped>
    .please {
        text-align: right;
        padding: 20px 0 0 20px;
    }

    .code-active {
        color: #4D77FD !important;
    }

    .login-code {
        border-left: 1px solid #EDEFF2;
        padding: 0 20px;
        text-align: center;
        width: 400px;
        font-size: 30px;
        color: #909399;
    }


    .blue {
        color: #4D77FD;
    }

    .login-right {
        color: #303133;
        font-size: 24px;
    }

    .login-left {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }

    .button-active {
        background-color: #4D77FD !important;
        opacity: 1 !important;
    }

    .login-button {
        height: 88px;
        background-color: #4D77FD;
        opacity: .5;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 44px;
        margin-top: 70px;
    }

    .name-input::-webkit-input-placeholder {
        color: #BFC2CC;
    }

    .login-input {
        border-bottom: 1px solid #EDEFF2;
        padding: 20px 0;
        margin-top: 40px;
        color: #303133;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .login-input input {
        width: 100%;
    }

    .login-active {
        font-size: 48px;
        color: #0A1C33;
    }

    .login {
        display: flex;
        align-items: center;
        font-size: 30px;
        color: #606266;
        padding: 60px 0;
    }
</style>
